var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-10" },
    [
      _c(
        "div",
        [
          _c(
            "a-button",
            {
              staticStyle: { margin: "5px" },
              attrs: { icon: "plus", size: "large", type: "primary" },
              on: { click: _vm.handle2022Version }
            },
            [_vm._v("添加2022年重新识别大币种对版任务（每次10000条）")]
          )
        ],
        1
      ),
      _vm.list && _vm.list.length
        ? _c(
            "div",
            { staticStyle: { background: "#ECECEC", padding: "10px" } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 5] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-card",
                        { staticClass: "card-item-box" },
                        [
                          _c("a-statistic", {
                            attrs: {
                              title: "重新对版总任务数",
                              value: _vm.list.length,
                              "value-style": { color: "blue" }
                            }
                          }),
                          _c(
                            "a-button",
                            {
                              staticClass: "reset-data",
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.handleGetTaskList("version")
                                }
                              }
                            },
                            [_vm._v("刷新数据")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-card",
                        [
                          _c("a-statistic", {
                            attrs: {
                              title: "重新对版待处理对版总记录数",
                              value: _vm.computedTotalNum(_vm.list),
                              "value-style": { color: "red" }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-box" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "a-card",
                    {
                      key: index,
                      staticClass: "card-item",
                      attrs: { title: item.coinName }
                    },
                    [
                      _c("p", { staticClass: "font-32 color-green" }, [
                        _vm._v(_vm._s(item.taskNum))
                      ]),
                      item.modelVersion && item.modelVersion !== "null"
                        ? _c("p", { staticClass: "color-blue" }, [
                            _vm._v("版本：" + _vm._s(item.modelVersion))
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.listV2 && _vm.listV2.length
        ? _c(
            "div",
            { staticStyle: { background: "#ECECEC", padding: "10px" } },
            [
              _c("a-divider", [_vm._v("重新识别大币种")]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-card",
                        { staticClass: "card-item-box" },
                        [
                          _c("a-statistic", {
                            attrs: {
                              title: "重新识别大币种对版总任务数",
                              value: _vm.listV2.length,
                              "value-style": { color: "blue" }
                            }
                          }),
                          _c(
                            "a-button",
                            {
                              staticClass: "reset-data",
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.handleGetTaskList("identify")
                                }
                              }
                            },
                            [_vm._v("刷新数据")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-card",
                        [
                          _c("a-statistic", {
                            attrs: {
                              title: "重新识别大币种对版待处理对版总记录数",
                              value: _vm.computedTotalNum(_vm.listV2),
                              "value-style": { color: "red" }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-box" },
                _vm._l(_vm.listV2, function(item, index) {
                  return _c(
                    "a-card",
                    {
                      key: index,
                      staticClass: "card-item",
                      attrs: { title: item.coinName }
                    },
                    [
                      _c("p", { staticClass: "font-32 color-green" }, [
                        _vm._v(_vm._s(item.taskNum))
                      ]),
                      item.modelVersion && item.modelVersion !== "null"
                        ? _c("p", { staticClass: "color-blue" }, [
                            _vm._v("版本：" + _vm._s(item.modelVersion))
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.list.length <= 0 && _vm.listV2.length <= 0
        ? _c("a-empty", { staticClass: "null", attrs: { description: false } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }