var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              dropdownMenuStyle: { "max-height": "350px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择大币种"
            },
            on: { search: _vm.searchData },
            model: {
              value: _vm.params.coinId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinId", $$v)
              },
              expression: "params.coinId"
            }
          },
          _vm._l(_vm.bigCoinList, function(items) {
            return _c(
              "a-select-option",
              { key: items.id, attrs: { value: items.id } },
              [_vm._v(_vm._s(items.coinKindName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            staticStyle: { margin: "5px", width: "240px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "选择类型"
            },
            model: {
              value: _vm.params.type,
              callback: function($$v) {
                _vm.$set(_vm.params, "type", $$v)
              },
              expression: "params.type"
            }
          },
          _vm._l(_vm.taskTypeList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              "filter-option": _vm.untils.filterOption,
              showSearch: "",
              size: "small",
              placeholder: "模型版本",
              dropdownStyle: { "max-height": "1000px" }
            },
            model: {
              value: _vm.params.modelVersion,
              callback: function($$v) {
                _vm.$set(_vm.params, "modelVersion", $$v)
              },
              expression: "params.modelVersion"
            }
          },
          _vm._l(_vm.modelVersionList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "header-item",
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "标签筛选"
            },
            model: {
              value: _vm.params.tag,
              callback: function($$v) {
                _vm.$set(_vm.params, "tag", $$v)
              },
              expression: "params.tag"
            }
          },
          _vm._l(_vm.taskTagList, function(items) {
            return _c(
              "a-select-option",
              { key: items.value, attrs: { value: items.value } },
              [_vm._v(_vm._s(items.name))]
            )
          }),
          1
        ),
        _c(
          "a-button",
          {
            staticStyle: { margin: "5px" },
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("添加任务")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("a-alert", {
          attrs: {
            message:
              "操作提示:后台会每次按照id从大到小添加到任务列表2000条,添加时保持相同条件的筛选直到添加完之后在切换筛选条件",
            type: "error"
          }
        }),
        _c("TaskList", {
          attrs: {
            list: _vm.list,
            "list-v2": _vm.listV2,
            show: _vm.isShowOtherParams
          },
          on: { resetData: _vm.handleResetData }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }