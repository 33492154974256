/** 类型刷选 */
export const taskTypeList = [
    {name: '未产生对版记录', value: 'notDuiban'},
    // {name: '有对版记录', value: 'haveDuiban'},
    {name: '重新对版-不识别大币种', value: 'resetDuiban'},
    {name: '重新对版-重新识别大币种', value: 'notCoinResetDuiban'},
]
/** 标签刷选 */
export const taskTagList = [
    {name: '多答案', value: 'many'},
    {name: '低概率', value: 'low'},
]
