<template>
  <div class="mt-10">
    <div>
      <a-button
          icon="plus"
          size="large"
          @click="handle2022Version"
          type="primary"
          style="margin: 5px;"
      >添加2022年重新识别大币种对版任务（每次10000条）</a-button>
    </div>
    <div v-if="list && list.length" style="background: #ECECEC; padding: 10px">
      <a-row :gutter="[16, 5]">
        <a-col :span="12">
          <a-card class="card-item-box">
            <a-statistic
                title="重新对版总任务数"
                :value="list.length"
                :value-style="{ color: 'blue' }"
            >
            </a-statistic>
            <a-button
                @click="handleGetTaskList('version')"
                type="primary"
                class="reset-data"
            >刷新数据</a-button>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-statistic
                title="重新对版待处理对版总记录数"
                :value="computedTotalNum(list)"
                :value-style="{ color: 'red' }"
            >
            </a-statistic>
          </a-card>
        </a-col>
      </a-row>
      <div class="content-box">
        <a-card
            v-for="(item, index) in list"
            :key="index" class="card-item"
            :title="item.coinName"
        >
          <p class="font-32 color-green">{{ item.taskNum }}</p>
          <p class="color-blue" v-if="item.modelVersion && item.modelVersion !== 'null'">版本：{{ item.modelVersion }}</p>
        </a-card>
      </div>
    </div>
    <div v-if="listV2 && listV2.length" style="background: #ECECEC; padding: 10px">
      <a-divider>重新识别大币种</a-divider>
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <a-card class="card-item-box">
            <a-statistic
                title="重新识别大币种对版总任务数"
                :value="listV2.length"
                :value-style="{ color: 'blue' }"
            >
            </a-statistic>
            <a-button
                @click="handleGetTaskList('identify')"
                type="primary"
                class="reset-data"
            >刷新数据</a-button>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-statistic
                title="重新识别大币种对版待处理对版总记录数"
                :value="computedTotalNum(listV2)"
                :value-style="{ color: 'red' }"
            >
            </a-statistic>
          </a-card>
        </a-col>
      </a-row>
      <div class="content-box">
        <a-card
            v-for="(item, index) in listV2"
            :key="index" class="card-item"
            :title="item.coinName"
        >
          <p class="font-32 color-green">{{ item.taskNum }}</p>
          <p class="color-blue" v-if="item.modelVersion && item.modelVersion !== 'null'">版本：{{ item.modelVersion }}</p>
        </a-card>
      </div>
    </div>
    <a-empty :description="false" class="null" v-if="list.length <= 0 && listV2.length <= 0" />
  </div>
</template>
<script>
export default {
  props: ['list', 'listV2', 'show'],
  data() {
    return {
      isLoadingVersion: false
    }
  },
  computed: {
    computedTotalNum() {
      return (list) => {
        let total = 0
        list.forEach(el => {
          total += el.taskNum
        })
        return total
      }
    }
  },
  methods: {
    /** 重新识别对版 */
    async handle2022Version() {
      if (this.isLoadingVersion) return
      this.$loading.show()
      this.isLoadingVersion = true
      const res = await this.axios("/dq_admin/duibanTask/add2022ResetCoinDuiban");
      this.$loading.hide()
      this.isLoadingVersion = false
      if (res.status != 200) return this.$message.error(res.message)
      this.$message.success(res.message)
    },
    /** 获取列表数据 */
    handleGetTaskList(type) {
      this.$emit('resetData', type)
    }
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: #3f8600 !important;
}
.font-32 {
  font-size: 24px !important;
}
.color-gray {
  color: gray !important;
}
.color-blue {
  color: #2d8cf0 !important;
}
.null {
  margin-top: 100px;
}
.mt-10 {
  margin-top: 10px;
}
.content-box {
  margin-bottom: 10px;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card-item {
  margin-right: 10px;
}
.card-item::v-deep .ant-card-body {
  min-width: 200px !important;
  max-width: 600px !important;
  height: 150px !important;
}
.card-item::v-deep .ant-card-head-title {
  color: gray !important;
}
.card-item-box {
  position: relative;
}
.reset-data {
  position: absolute;
  right: 10px;
  top: 45px;
}
</style>